import { useEffect, useState } from 'react';
import './App.css';
import MainView from './Views/MainView';
import Axios from 'axios';

function App() {

  const [location, setLocation] = useState({});
  const [currentWeather, setCurrentWeather] = useState({});


  useEffect(() => {
      Axios.get('https://api.weatherapi.com/v1/current.json?q=Krapina&key=9fddbf564ed04e13a30134909230210')
      .then(resp => {
        console.log("KRAPINA ", resp.data);
        setLocation(resp.data.location);
        setCurrentWeather(resp.data.current)
      });
  }, []);

  
 

  return (
    <div className="App">
     {/* <MainView /> */}
     <p>{location?.name}</p>
     <p>{location?.region}</p>
     <p>{location?.country}</p>
     <p>Temp</p>
     <p>Temp: {currentWeather?.temp_c} C</p>
     <p>Real feel: {currentWeather?.feelslike_c}</p>
     <p>Brzina vjetra: {currentWeather?.wind_kph} km/h</p>
     <p>Smjer vjetra: {currentWeather?.wind_dir}</p>
     <img src={currentWeather?.condition?.icon} />
    </div>
  );
}

export default App;
